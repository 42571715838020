const quotes = document.querySelectorAll('.quote-headshot');

quotes.forEach((quote) => {
    const headshotVid = quote.querySelector('video');

    if (headshotVid) {
        function handleVideoIntersection(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    headshotVid.play();
                    headshotObserver.unobserve(headshotVid);
                } else {
                    headshotVid.pause();
                }
            });
        }

        const headshotObserver = new IntersectionObserver(handleVideoIntersection, {
            threshold: 1
        });

        headshotObserver.observe(headshotVid);
    }
});