import EmblaCarousel from "embla-carousel";
import Autoplay from "embla-carousel-autoplay";
import { addDotBtnsAndClickHandlers } from "../embla-utils";

let timedSliders = document.querySelectorAll('.timed-slider');

timedSliders.forEach((slider) => {
    let duration = parseInt(slider.dataset.duration);
    console.log('durr', duration);
    let emblaNode = slider.querySelector('.timed-slider__slider');
    let dotsNode = slider.querySelector('.timed-slider__dots');
    // let autoplay = Autoplay(emblaNode, { delay: 5000 });

    let embla = EmblaCarousel(emblaNode, { loop: true }, [
        Autoplay({ playOnInit: true, delay: duration, stopOnInteraction: false })
      ]);
    let removeDotBtnsClickHandlers = addDotBtnsAndClickHandlers(embla, dotsNode, 'div');

    embla.on('init', function() {
        slider.querySelector('.embla__dot:first-child').classList.remove('embla__dot--selected');
        setTimeout(() => {
            slider.querySelector('.embla__dot:first-child').classList.add('embla__dot--selected');
        }
        , 10);
    });
    // embla.on('destroy', autoplay.stop);

    embla.on('destroy', removeDotBtnsClickHandlers);

});
